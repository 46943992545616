@import '../../../scss/index.scss';

.header-top {
    background-color: $background-grey-dark;
    height: 68px;
    padding: 15px 0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
    display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;

    &__left {
        position: absolute;
        // flex-basis: 33.333333%;

        .ant-image {
            width: 60px;
            height: 60px;
            position: absolute;
            left: 10px;
            top: -11px;

            .ant-image-img {
                border-radius: 50%;
                height: 60px;
            }
        }

        .back-btn {
            background-color: transparent;
            border-color: transparent;
            color: white;
            margin-left: 15px;
            font-size: 25px;
            width: 50px;
            height: 40px;
        }
        .back-handle-btn {
            flex-grow: 1;
            width: 33%;
            line-height: 2.6;
            padding-left: 10px;
        }
    }

    &__center {
        width: 100%;
        text-align: center;
        line-height: 1.5;
        margin-left: 50px;
        margin-right: 50px;

        h1 {
            color: $font-light;
            font-size: 24px;
            text-transform: capitalize;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &__right {
        position: absolute;
        right: 0;

        .search-btn {
            background-color: transparent;
            border-color: transparent;
            color: white;
            margin-right: 10px;
            width: 50px;
            height: 40px;
        }
    }
}
